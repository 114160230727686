import { render, staticRenderFns } from "./video-player.vue?vue&type=template&id=32e92bb0&scoped=true&"
import script from "./video-player.vue?vue&type=script&lang=js&"
export * from "./video-player.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e92bb0",
  null
  
)

export default component.exports